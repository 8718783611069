import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import UiSection from "@components/common/newUISection";

const WeatherAdvisory = ({getData, sectionId}) => {
  const state = useSelector((state) => state?.cms);
  const [WeatherAdvisory, setWeatherAdvisory] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.WeatherAdvisory && stored?.WeatherAdvisory.length > 0) {
      setWeatherAdvisory(
        stored?.WeatherAdvisory.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored]);

  return (
    <div className='mt-2 px-1'>
      <Row>
        <UiSection
            dataList={WeatherAdvisory}
            getData={getData}
            sectionName='WeatherAdvisory'
            sectionId={sectionId}
        />
      </Row>
    </div>
  );
};

export default WeatherAdvisory;
