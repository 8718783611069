import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Input, Label } from "reactstrap";
import RangeSelector from "../../@core/components/common/RangePicker";
import TableList from "@components/common/TableList";
import Sidebar from "@components/common/VerticalRightSideBar";
import SideBarContent from "@components/common/VerticalRightSideBar/SideBarContent";
import EmailForm from "../../@core/components/common/emailPopup";
import "react-day-picker/dist/style.css";
import toaster from "@components/common/Toaster";
import API from "@api";
import moment from "moment/moment";
import "./index.scss";

const tableHeaderList = {
  status: "status",
  // channel: "channel",
  pnr: "bookingId",
  guest: [
    { data: "user.firstName", seprator: " " },
    { data: "user.lastName" },
    { type: "alternate", data: "user.name" },
  ],
  "Origin - Destination": [
    { data: "origin", seprator: " - " },
    { data: "destination" },
  ],
  "trip type": "tripType",
  "Travel Date": [
    {
      data: "departureDate",
      type: "date",
      format: "YYYY/MM/DD",
      seprator: " ",
    },
    {
      data: "departureTime",
      seprator: " \n \n",
    },
    { data: "returnDate", type: "date", format: "YYYY/MM/DD", seprator: " " },
    {
      data: "returnDepartureTime",
    },
  ],
  "Booking Date": [
    { data: "createdAt", type: "date", format: "YYYY/MM/DD HH:mm" },
  ],
};

const detailsTableHeaderList = {
  PNR: "bookingId",
  "Full Name": "fullName",
  "booking status": "status",
  "Transaction ID": "transactionId",
  "payment status": "paymentStatus",
  origin: "origin",
  destination: "destination",
  "Booking Date": "bookingDate",
  "Departure Date": "departureDate",
  "Return Date": "returnDate",
  "trip type": "tripType",
  "Departure Flight Number": "departureFlightNo",
  "Return Flight Number": "returnFlightNo",
  "Total booking amount": "bookingAmount",
  "Total Fare": "totalFare",
  "Web convenience fee": "convenienceFee",
  "Taxes and Fees": "totalTax",
  email: "email",
  phone: "phone",
  "whatsApp Status": "whatsappStatus",
  "email Status": "emailStatus",
  "SMS Status": "smsStatus",
  Adults: "passengersCount.adults",
  Infants: "passengersCount.infants",
  Children: "passengersCount.children",
};

const Booking = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [bookings, setBookings] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayRightSidebar, setDisplayRightSidebar] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [sortHeader, setSortHeader] = useState([
    { headerText: "Travel Date", headerData: "departureDate", sort: -1 },
    { headerText: "Booking Date", headerData: "createdAt", sort: -1 },
  ]);
  const [sort, setSort] = useState({ sortKey: "departureDate", sortValue: -1 });
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarTo, setShowCalendarTo] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const defaultSelected = {
    from: new Date(moment().subtract(1, "months")),
    to: new Date(),
  };
  const [range, setRange] = useState(defaultSelected);
  const getList = async () => {
    const query = {
      search,
      status,
      sortKey: sort.sortKey,
      sortValue: sort.sortValue,
      startDate: range.from,
      endDate: range.to,
    };
    const res = await API.getAllBookings({ limit, page, query });
    if (res?.data?.success) {
      setBookings(res.data?.data?.data);
      setTotalPage(Math.ceil(res?.data?.data?.count / limit));
    }
  };

  const getBookingById = async (id) => {
    const res = await API.getBookingById(id);
    if (res?.data?.success) {
      setSelectedItemData(res.data?.data);
    }
  };

  const handleChange = (value) => {
    setSearch(value);
  };

  const downloadCSVFile = async () => {
    const query = {
      search,
      status,
      sortKey: sort.sortKey,
      sortValue: sort.sortValue,
      startDate: range.from,
      endDate: range.to,
    };

    const res = await API.downloadBookingCSV({
      query,
    });

    const url = window.URL.createObjectURL(new Blob([res.data?.data]));

    const link = document.createElement("a");
    link.href = url;

    const fileName = `flybig-bookings_${moment(new Date(range.from)).format(
      "DD-MMM-YYYY"
    )} to ${moment(new Date(range.to)).format("DD-MMM-YYYY")}`;

    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);

    link.click();

    toaster("success", "CSV file downloaded successfully!");

    link.remove();
  };

  const downloadCSV = async () => {
    await downloadCSVFile();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateDateRange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setRange({
        from: moment(startDate).format("MM-DD-YYYY"),
        to: moment(endDate).format("MM-DD-YYYY"),
      });
    }
  };

  const handelDayChangeFrom = ({ from, to }) => {
    let currentEnd = range.to;
    const newFrom = to === range.to ? from : to;
    if (currentEnd <= newFrom) {
      currentEnd = new Date(moment(newFrom).add(1, "days"));
    }
    setRange({ from: newFrom, to: currentEnd });
    setShowCalendar(false);
  };
  const handelDayChangeTo = ({ to, from }) => {
    const currentStart = range.from;
    const newTo = from === range.from ? to : from;
    if (newTo > currentStart) {
      setRange({ to: newTo, from: currentStart });
      setShowCalendarTo(false);
    }
  };
  useEffect(() => {
    if (showCalendar) {
      setShowCalendarTo(false);
    }
  }, [showCalendar]);
  useEffect(() => {
    if (showCalendarTo) {
      setShowCalendar(false);
    }
  }, [showCalendarTo]);
  useEffect(() => {
    getList();
  }, [search, status, sort, range, page]);

  // useEffect(() => {
  //   getList();
  // }, [page, queryParams]);

  useEffect(() => {
    if (selectedItem) {
      getBookingById(selectedItem);
    }
  }, [selectedItem]);
  return (
    <div>
      {displayRightSidebar && (
        <Sidebar
          menuCollapsed={false}
          display={displayRightSidebar}
          setDisplay={setDisplayRightSidebar}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        >
          <SideBarContent
            selectedItemData={selectedItemData}
            headerTitle="Booking Details"
            tableHeaderList={detailsTableHeaderList}
          />
        </Sidebar>
      )}
      <EmailForm
        showModal={showModal}
        closeModal={closeModal}
        setShowModal={setShowModal}
        downloadCSVFile={downloadCSVFile}
      />
      <Row className="mt-2 mx-1">
        <Col sm="12" md="4">
          <FormGroup>
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Search By Email, Phone or PNR
              </Label>

              <Input
                type="text"
                value={search}
                id="search"
                name="search"
                placeholder="Search By Email, Phone or PNR"
                onChange={(e) => handleChange(e.target.value)}
              />
            </FormGroup>
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup>
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Filter by Booking Date
              </Label>
              <div className="form-control">
                <RangeSelector updateDuration={updateDateRange} />
              </div>
              {/* <div class="form-control d-flex justify-content-between">
                <div
                  className="__calendarIcon"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <Calendar />
                </div>
                {moment(range.from).format("YYYY-MM-DD")} -{" "}
                {moment(range.to).format("YYYY-MM-DD")}
                <div
                  className="__calendarIcon"
                  onClick={() => setShowCalendarTo(!showCalendarTo)}
                >
                  <Calendar />
                </div>
              </div>
              <div className="__range">
                {showCalendar && !showCalendarTo && (
                  <DayPicker
                    className={showCalendar ? "" : "__calenderHide"}
                    // numberOfMonths={2}
                    mode="range"
                    defaultMonth={range.from}
                    selected={range}
                    onSelect={handelDayChangeFrom}
                    pagedNavigation
                  />
                )}
                {!showCalendar && showCalendarTo && (
                  <DayPicker
                    className={showCalendarTo ? "" : "__calenderHide"}
                    // numberOfMonths={2}
                    mode="range"
                    defaultMonth={range.to}
                    selected={range}
                    onSelect={handelDayChangeTo}
                    pagedNavigation
                  />
                )}
              </div> */}
            </FormGroup>
          </FormGroup>
        </Col>
        <Col sm="10" md="4">
          <FormGroup>
            <FormGroup className="w-100">
              <Label className="form-label" for="description">
                Filter by Status
              </Label>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="confirmed">Confirmed</option>
                <option value="cancelled">Cancelled</option>
                <option value="failed">Failed</option>
              </select>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
      {bookings && (
        <TableList
          headerTitle="All Bookings"
          tableHeaderList={tableHeaderList}
          tableData={bookings}
          setBookings={setBookings}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
          rightSideBar={true}
          setDisplayRightSidebar={setDisplayRightSidebar}
          setSelectedItem={setSelectedItem}
          sort={sort}
          setSort={setSort}
          sortHeader={sortHeader}
          setSortHeader={setSortHeader}
          downloadCSVFile={downloadCSV}
        />
      )}
    </div>
  );
};

export default Booking;
