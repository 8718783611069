import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import TablePagination from "../TablePagination";
import moment from "moment";
import { ArrowDown, ArrowUp, Circle } from "react-feather";
import JsPDF from "jspdf";

import "./index.scss";

const createQueryKey = (key, item) => {
  const keyList = key.split(".");

  let queryKey;

  if (keyList.length === 1) {
    queryKey = item[keyList[0]];
  } else if (keyList.length === 2 && item[keyList[0]]) {
    queryKey = item[keyList[0]]?.[keyList[1]];
  } else if (keyList.length === 3 && item[keyList[0]] && [keyList[1]]) {
    queryKey = item[keyList[0]]?.[keyList[1]]?.[keyList[2]];
  } else if (
    keyList.length === 4 &&
    item[keyList[0]] && [keyList[1]] && [keyList[2]]
  ) {
    queryKey = item[keyList[0]]?.[keyList[1]]?.[keyList[2]]?.[keyList[3]];
  }
  return queryKey;
};

const generatFormatedKey = (key, item) => {
  let queryKey = "";
  if (Array.isArray(key)) {
    key.forEach((obj) => {
      let currentKey = createQueryKey(obj.data, item) || "";
      if (obj.type) {
        if (obj.type === "date") {
          if (currentKey) {
            currentKey = moment(new Date(currentKey)).format(obj.format);
          }
        } else if (obj.type === "alternate") {
          if (queryKey.trim() !== "") {
            currentKey = "";
          }
        }
      }
      queryKey += `${currentKey || ""}${obj.seprator || ""}`;
    });
  } else {
    queryKey = createQueryKey(key, item);
  }
  return queryKey;
};

const generatePDF = () => {
  const report = new JsPDF("landscape", "pt", "a4");
  const element = document.querySelector("#report");
  element.style.width = "297mm";
  element.style.padding = "10px 2px 10px 2px";
  report.html(element).then(() => {
    report.save("report.pdf");
  });
};

const TableList = ({
  headerTitle,
  tableData,
  tableHeaderList,
  setPage,
  page,
  totalPage,
  rightSideBar,
  setDisplayRightSidebar,
  setSelectedItem,
  sort,
  setSort,
  sortHeader,
  setSortHeader,
  downloadCSVFile,
}) => {
  const openSideBar = (key) => {
    if (rightSideBar) {
      setDisplayRightSidebar(true);
      setSelectedItem(key);
    }
  };

  const getTableData = () =>
    tableData?.map((item) => {
      return (
        item.channel === "website" && (
          <tr key={item._id} style={{ userSelect: "none" }}>
            {Object.values(tableHeaderList).map((key) => {
              let queryKey = generatFormatedKey(key, item);
              if (key === "status") {
                return (
                  <td key={key}>
                    <Circle
                      stroke="white"
                      pill
                      fill={
                        queryKey === "cancelled"
                          ? "#f43f5e"
                          : queryKey === "confirmed"
                          ? "#22c55e"
                          : "#4b5563"
                      }
                      className="mr-1"
                    />
                  </td>
                );
              } else if (
                (key === "name" || key === "bookingId") &&
                rightSideBar
              ) {
                return (
                  <td
                    key={key}
                    onClick={() => openSideBar(item._id)}
                    className="td-hoverable"
                  >
                    {queryKey}
                  </td>
                );
              } //else if (key === "channel") {
              //   return (
              //     <td key={key}>
              //       <Badge
              //         pill
              //         className={`mr-1 ${
              //           queryKey === "website" ? "badge-green" : "badge-amber"
              //         }`}
              //       >
              //         {queryKey?.toUpperCase()}
              //       </Badge>
              //     </td>
              //   );
              // }
              return <td key={item[key]}>{queryKey}</td>;
            })}
          </tr>
        )
      );
    });

  const sortBy = (index) => {
    const newHeader = sortHeader;
    newHeader[index].sort = newHeader[index].sort === 1 ? -1 : 1;
    setSort({
      sortKey: newHeader[index].headerData,
      sortValue: newHeader[index].sort,
    });
    setSortHeader(newHeader);
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>{headerTitle}</CardTitle>
            <div className="d-flex justify-content-between align-items-center">
              {/* <Button onClick={generatePDF} type="button" color="light">
                Export PDF
              </Button> */}
              <Button onClick={downloadCSVFile} type="button" color="light">
                Download Report
              </Button>
            </div>
          </CardHeader>
          <Table responsive id="report">
            <thead>
              <tr>
                {Object.keys(tableHeaderList)?.map((item) => {
                  return (
                    <th key={item}>
                      <div className="table__header d-flex justify-content-between align-items-center">
                        <div>{item}</div>
                        {sortHeader &&
                          sortHeader.map((header, index) => {
                            if (item === header.headerText) {
                              return (
                                <div
                                  className="table__header__sort"
                                  onClick={() => sortBy(index)}
                                >
                                  {header.sort === 1 ? (
                                    <ArrowDown />
                                  ) : (
                                    <ArrowUp />
                                  )}
                                </div>
                              );
                            }
                          })}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>{getTableData()}</tbody>
          </Table>
          <TablePagination
            totalPage={totalPage}
            setPage={setPage}
            page={page}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TableList;
