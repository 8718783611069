import {
  Layout,
  Upload,
  Circle,
  AlertCircle,
  Airplay,
  AlignCenter,
} from "react-feather";

export default [
  {
    id: "cms",
    title: "CMS",
    icon: <Layout />,
    badge: "light-warning",
    children: [
      {
        id: "cmsHomeContent",
        title: "HomeContent",
        icon: <Circle size={12} />,
        navLink: "/cms/home-content",
      },
      {
        id: "pdfUpload",
        title: "Upload PDF",
        icon: <Upload size={12} />,
        navLink: "/cms/pdf-upload",
      },
      {
        id: "bannerImage",
        title: "Banner Image",
        icon: <Upload size={12} />,
        navLink: "/cms/banner-image",
      },
      {
        id: "flybigAlert",
        title: "Flybig Alert",
        icon: <AlertCircle size={12} />,
        navLink: "/cms/alert-Config",
      },
      {
        id: "specialOffers",
        title: "Special Offers",
        icon: <Airplay size={12} />,
        navLink: "/cms/special-offers",
      },
      {
        id: "about",
        title: "About",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/about",
      },
      {
        id: "coc",
        title: "Conditions of Carriage",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/coc",
      },
      {
        id: "tac",
        title: "Terms & Conditions",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/tac",
      },
      {
        id: "specialAssistance",
        title: "Special Assistance",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/specialAssistance",
      },
      {
        id: "WeatherAdvisory",
        title: "Weather Advisory",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/WeatherAdvisory",
      },
      {
        id: "privacy",
        title: "Privacy",
        icon: <AlignCenter size={12} />,
        navLink: "/cms/privacy",
      },
    ],
  },
];
