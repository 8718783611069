import HomeContent from "../../views/Cms/HomeContent";
import PdfUpload from "../../views/Cms/PDFUpload";
import BannerImage from "../../views/Cms/BannerImage";
import AlertConfig from "../../views/Cms/AlertConfig";
import SpecialOffer from "../../views/Cms/SpecialOffer";
import FaqCatagories from "../../views/Cms/FAQ/FaqCatagories";
import Faqs from "../../views/Cms/FAQ";
import AboutUs from "../../views/Cms/AboutUs";
import ContactUs from "../../views/Cms/ContactUs";
import COC from "../../views/Cms/COC";
import TAC from "../../views/Cms/TermsAndCondation";
import SpecialAssistance from "../../views/Cms/SpecialAssistance";
import WeatherAdvisory from "../../views/Cms/WeatherAdvisory";
import Privacy from "../../views/Cms/Privacy";

const CmsRoutes = [
  {
    path: "/cms/home-content",
    exact: true,
    component: HomeContent,
  },
  {
    path: "/cms/pdf-upload",
    exact: true,
    component: PdfUpload,
  },
  {
    path: "/cms/banner-image",
    exact: true,
    component: BannerImage,
  },
  {
    path: "/cms/alert-Config",
    exact: true,
    component: AlertConfig,
  },
  {
    path: "/cms/special-offers",
    exact: true,
    component: SpecialOffer,
  },
  {
    path: "/cms/faqCatagories",
    exact: true,
    component: FaqCatagories,
  },
  {
    path: "/cms/faqs",
    exact: true,
    component: Faqs,
  },
  {
    path: "/cms/about",
    exact: true,
    component: AboutUs,
  },
  {
    path: "/cms/coc",
    exact: true,
    component: COC,
  },
  {
    path: "/cms/tac",
    exact: true,
    component: TAC,
  },
  {
    path: "/cms/specialAssistance",
    exact: true,
    component: SpecialAssistance,
  },
  {
    path: "/cms/WeatherAdvisory",
    exact: true,
    component: WeatherAdvisory,
  },
  {
    path: "/cms/privacy",
    exact: true,
    component: Privacy,
  },
];

export default CmsRoutes;
