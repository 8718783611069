import React from "react";
import { Table, Badge } from "reactstrap";
import { Col, Row } from "reactstrap";
import { dateFormatHandler } from "@utils";
import "./sidebar.scss";

const User = ({ tableHeaderList, selectedItemData = {}, headerTitle }) => {
  const ignoreCapitalize = ["Transaction ID", "email"];

  const conditionalFields = [
    "Adults",
    "Infants",
    "Children",
    "Return Date",
    "Return Flight Number",
  ];

  const getTableData = () => (
    <tbody>
      {Object.entries(tableHeaderList)
        .map(([key, value]) => {
          const keyList = value.split(".");

          let queryKey;

          if (key === "transactionId") {
            console.log({ key, value });
          }

          if (keyList.length === 1) {
            queryKey = selectedItemData[keyList[0]];
          } else if (keyList.length === 2 && selectedItemData[keyList[0]]) {
            queryKey = selectedItemData[keyList[0]]?.[keyList[1]];
          } else if (
            keyList.length === 3 &&
            selectedItemData[keyList[0]] && [keyList[1]]
          ) {
            queryKey = selectedItemData[keyList[0]]?.[keyList[1]]?.[keyList[2]];
          } else if (
            keyList.length === 4 &&
            selectedItemData[keyList[0]] && [keyList[1]] && [keyList[2]]
          ) {
            queryKey =
              selectedItemData[keyList[0]]?.[keyList[1]]?.[keyList[2]]?.[
                keyList[3]
              ];
          }

          if (value === "status") {
            return (
              <tr>
                <td>{key}</td>
                <td key={key}>
                  <Badge
                    pill
                    color={
                      queryKey === "pending"
                        ? "warning"
                        : queryKey === "confirmed"
                        ? "success"
                        : "danger"
                    }
                    className="mr-1"
                  >
                    {queryKey?.toUpperCase()}
                  </Badge>
                </td>
              </tr>
            );
          } else if (
            ["departureDate", "returnDate", "bookingDate"].indexOf(value) >
              -1 &&
            queryKey
          ) {
            return (
              <tr>
                <td>{key}</td>
                <td>{dateFormatHandler(queryKey)}</td>
              </tr>
            );
          } else if (value === "paymentStatus") {
            return (
              <tr>
                <td>{key}</td>
                <td key={key}>
                  <Badge
                    pill
                    color={
                      queryKey === "pending"
                        ? "warning"
                        : queryKey === "success"
                        ? "success"
                        : "danger"
                    }
                    className="mr-1"
                  >
                    {queryKey?.toUpperCase()}
                  </Badge>
                </td>
              </tr>
            );
          }

          if (conditionalFields.indexOf(key) > -1 && !queryKey) {
            return null;
          } else {
            return (
              <tr>
                <td>{key}</td>
                <td
                  key={key}
                  style={
                    ignoreCapitalize.indexOf(key) > -1
                      ? { textTransform: "none" }
                      : {}
                  }
                >
                  {queryKey || " - "}
                </td>
              </tr>
            );
          }
        })
        .filter((info) => info)}
    </tbody>
  );

  return (
    <Row>
      <Col>
        <h3 className="header">{headerTitle}</h3>
        <Table className="details-table">
          <tbody>{getTableData()}</tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default User;
