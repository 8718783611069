import React from "react";
import "./index.scss";
import Flatpickr from "react-flatpickr";

function RangeSelector({ updateDuration }) {
  const options = {
    mode: "range",
    static: true,
    monthSelectorType: "static",
    dateFormat: "M j, Y",
    defaultDate: [new Date().setDate(new Date().getDate() - 30), new Date()],
    prevArrow:
      '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace("to", "-");
      const customClass = "";
      instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace("to", "-");
      if (selectedDates?.length === 2) {
        updateDuration({
          startDate: selectedDates[0],
          endDate: selectedDates[1] ? selectedDates[1] : selectedDates[0],
        });
      }
    },
  };

  return (
    <div className="">
      <Flatpickr
        className=" w-[100%] border-0 dateRangeLabel"
        options={options}
      />
    </div>
  );
}

export default RangeSelector;
