// ** React Imports
import { Fragment, useState, useRef } from "react";
import { X } from "react-feather";

// ** Third Party Components
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSkin } from "@hooks/useSkin";
import "./index.scss";

const Sidebar = (props) => {
  // ** Props
  const { menuCollapsed, display, children, setDisplay } = props;

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false);
  const [skin, setSkin] = useSkin();

  // ** Ref
  const shadowRef = useRef(null);
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true);
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef?.current?.classList?.contains("d-block")) {
        shadowRef?.current?.classList?.add("d-block");
      }
    } else {
      if (shadowRef?.current?.classList?.contains("d-block")) {
        shadowRef?.current?.classList?.remove("d-block");
      }
    }
  };
  return (
    <Fragment>
      <div
        className={classnames(
          "main-menu menu-fixed menu-accordion menu-shadow sidebar-right-container",
          {
            expanded: menuHover || menuCollapsed === false,
            "menu-light": skin !== "semi-dark" && skin !== "dark",
            "menu-dark": skin === "semi-dark" || skin === "dark",
            "d-none": display === false,
            "d-block": display === true,
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        <Fragment>
          <PerfectScrollbar
            className="main-menu-content"
            options={{ wheelPropagation: false }}
            onScrollY={(container) => scrollMenu(container)}
          >
            <div className="navigation navigation-main">
              <X
                size={25}
                className="close-btn"
                onClick={() => setDisplay(false)}
              />
              {children}
            </div>
          </PerfectScrollbar>
        </Fragment>
      </div>
    </Fragment>
  );
};

export default Sidebar;
