import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import UiSection from "@components/common/newUISection";

const Privacy = ({getData, sectionId}) => {
  const state = useSelector((state) => state?.cms);
  const [privacy, setPrivacy] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.privacy && stored?.privacy.length > 0) {
      setPrivacy(
        stored?.privacy.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored]);

  return (
    <div className='mt-2 px-1'>
      <Row>
        <UiSection
            dataList={privacy}
            getData={getData}
            sectionName='privacy'
            sectionId={sectionId}
        />
      </Row>
    </div>
  );
};

export default Privacy;
